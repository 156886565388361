import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppHomeBackground, LazyAppLoader, LazyAppNavigation, LazyAppServerList, LazyAppStatusPill, LazyAppSuspensionList, LazyAppToasts, LazyAppTooltip, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AppHomeBackground", LazyAppHomeBackground],
["AppLoader", LazyAppLoader],
["AppNavigation", LazyAppNavigation],
["AppServerList", LazyAppServerList],
["AppStatusPill", LazyAppStatusPill],
["AppSuspensionList", LazyAppSuspensionList],
["AppToasts", LazyAppToasts],
["AppTooltip", LazyAppTooltip],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

import type { ISearchParams, IServer, IServerStatistics, ISuspensionRes, IRanksRes, IUserData } from '~/types/types';

export default defineNuxtPlugin(() => {
  const $useApi = $fetch.create({
    baseURL: 'https://cs2master.eu/api',
    headers: {
      Accept: 'application/json',
    },
    cache: 'no-cache',
    onResponseError: err => {
      helpers.error.handleFetchError(err.response._data);
    },
  });

  const api = {
    index: {
      getHomepage() {
        return $useApi('/homepage');
      },
    },
    bans: {
      getBansList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/bans', { method: 'POST', body });
      },
    },
    mutes: {
      getMutesList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/mutes', { method: 'POST', body });
      },
    },
    warnings: {
      getWarningsList(body: ISearchParams): Promise<ISuspensionRes> {
        return $useApi('/warns', { method: 'POST', body });
      },
    },
    servers: {
      getServersList(): Promise<{ servers: IServer[] }> {
        return $useApi('/servers');
      },
      getServerStats(serverId: number): Promise<IServerStatistics> {
        return $useApi(`/servers/${serverId}`);
      },
      getTopPlayers(): Promise<IRanksRes> {
        return $useApi('/ranks/top');
      },
    },
    auth: {
      login(): Promise<void> {
        return $useApi('/auth');
      },
      userData(): Promise<IUserData> {
        return $useApi('/auth/user');
      },
      purchase(productId: number): Promise<{ url: string }> {
        return $useApi('/stripe/checkout', { method: 'POST', body: { productId } });
      },
    },
  };

  return {
    provide: {
      api,
    },
  };
});

<template>
  <nav>
    <div v-if="$store.index.screenWidth < 661" class="mobile-nav" :class="{ open: navOpen }">
      <button
        type="button"
        class="nav-burger"
        @click="navOpen = !navOpen"
      >
        <Icon name="ph:list-bold" />
      </button>

      <NuxtLink to="/" class="top-link">
        CS2<span class="hoverable">Master</span>
      </NuxtLink>
    </div>

    <div class="nav-wrapper" :class="{ open: navOpen }">
      <NuxtLink to="/" class="top-link">
        CS2<span class="hoverable">Master</span>
      </NuxtLink>

      <button v-if="!user" type="button" @click="linkToSteam">
        <img src="/img/steam.png" alt="Login with Steam account">
      </button>

      <div v-else class="flex-row gap-2 user" @click="linkToUserPage">
        <img :src="user.user.avatar" height="48" width="48">
        <div class="flex-col gap-1 justify-c">
          <p>{{ user.user.displayName }}</p>
          <div class="flex-row gap-2 align-c">
            <p v-if="user.vip">{{ objects.vipPlans[user.vip.group] }}</p>
            <img v-if="user.rank" :src="`/ranks/${Math.max(0, user.rank.rank - 1)}.svg`" height="20">
          </div>
        </div>
      </div>

      <template v-for="(link0, idx0) of links" :key="`level1-${idx0}`">
        <NuxtLink
          :to="`${link0.slug || '/'}`"
          class="nav-link level0"
          :class="{ active: isLinkActive(`${link0.slug}`) }"
        >
          {{ link0.title }}
        </NuxtLink>
        <template v-for="(link1, idx1) of link0.sub" :key="`level2-${idx1}`">
          <NuxtLink
            :to="`${link0.slug}${link1.slug}`"
            class="nav-link level1"
            :class="{ active: isLinkActive(`${link0.slug}${link1.slug}`) }"
          >
            {{ link1.title }}
          </NuxtLink>
        </template>
      </template>

      <NuxtLink
        to="/forums"
        class="nav-link level0"
        external
      >
        {{ $t('forum') }}
      </NuxtLink>

      <NuxtLink
        to="/skins/index.php"
        class="nav-link level0"
        external
      >
        Skins (!ws)
      </NuxtLink>

      <div class="languages">
        <NuxtLink v-if="locale !== 'en'" :to="switchLocale('en')">
          <Icon name="circle-flags:en" />
          English
        </NuxtLink>
        <NuxtLink v-if="locale !== 'lt'" :to="switchLocale('lt')">
          <Icon name="circle-flags:lt" />
          Lietuvių
        </NuxtLink>
      </div>
    </div>
  </nav>
</template>

<script lang="ts" setup>
import type { INavLink } from '~/types/types';

const switchLocale = useSwitchLocalePath();
const { locale, t } = useI18n();
const { $router, $store } = useNuxtApp();

const currentPath = ref('/');
const navOpen = ref(false);

const links = computed((): INavLink[] => {
  const localePrefix = locale['value'] !== 'lt' ? `/${locale['value']}` : '';
  return [
    {
      title: t('home'),
      slug: `${localePrefix}`,
      sub: [],
    },
    {
      title: t('servers'),
      slug: `${localePrefix}/servers`,
      sub: [],
    },
    {
      title: 'VIP',
      slug: `${localePrefix}/vip`,
      sub: [],
    },
    {
      title: t('topPlayers'),
      slug: `${localePrefix}/top`,
      sub: [],
    },
    {
      title: 'Suspensions',
      slug: `${localePrefix}/bans`,
      sub: [
        { title: 'Ban list', slug: '/list', sub: [] },
        { title: 'Muted players', slug: '/muted', sub: [] },
        { title: 'Warnings', slug: '/warnings', sub: [] },
      ],
    },
  ];
});

const user = computed(() => $store.index.user);

watch(() => $router.currentRoute.value.path, newPath => {
  currentPath.value = newPath;
  // $toast.info('Router', newPath);
  navOpen.value = false;
});

onMounted(() => {
  currentPath.value = $router.currentRoute.value.path;
});

function isLinkActive(path: string) {
  if (locale['value'] !== 'lt') {
    return currentPath.value === (path || '');
  }
  return currentPath.value === (path || '/');
}

function linkToSteam() {
  navigateTo('https://cs2master.eu/api/auth', { external: true });
}

function linkToUserPage() {
  navigateTo('/user');
}
</script>

<style lang="sass" scoped>
nav
  position: relative
  min-width: 28rem
  height: 100vh
  @media (max-width: 660px)
    min-width: 100%
    height: 9rem

  .nav-wrapper
    display: flex
    flex-direction: column
    left: 0
    top: 0
    bottom: 0
    min-width: 28rem
    max-width: 28rem
    gap: 1rem
    background: #224
    padding: 3rem 2rem
    position: fixed
    overflow: hidden
    @media (max-width: 660px)
      min-width: 0
      max-width: 100%
      right: 0
      display: none
      animation: expand .5s reverse
    &.open
      @media (max-width: 660px)
        display: flex
        z-index: 150
        animation: expand .5s

  .top-link
    font-size: 2.8rem
    font-weight: 600
    text-decoration: none
    color: #ffaa00
    margin-bottom: 2rem
    align-self: center
    span.hoverable
      color: #88a
      transition: all .2s
      &:hover
        color: #ffaa00

  .nav-link
    padding: 0.75rem 1rem
    border-radius: 1rem
    font-size: 2rem
    text-decoration: none
    color: #fff
    transition: all .1s
    &::before
      content: ''
      margin: 0
      color: #fff
      transition: all .2s
    &:not(.active):hover::before
      margin: 0.5rem
      content: '•'
      color: '#ffaa00'
    @for $i from 1 through 3
      &.level#{$i}
        margin-left: calc($i * 2rem)
    &.active
      background: #ffaa00
      color: #333

  .user
    margin: 2rem auto
    color: #fff
    font-size: 1.6rem
    font-weight: 400
    cursor: pointer
    transition: all .2s
    width: 100%
    &:hover p:first-child
      color: #ffaa00
    & > div
      width: calc(100% - 6.8rem)
      & > p
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        width: calc(100% - 1px)

  .languages
    display: flex
    flex-direction: column
    margin-top: auto
    color: #fff
    font-size: 1.6rem
    gap: 1rem
    svg
      width: 2rem
      height: 2rem
    a
      display: flex
      gap: 1rem

  .mobile-nav
    position: fixed
    top: 0
    left: 0
    right: 0
    padding: 2rem
    background: #11111a
    display: flex
    align-items: center
    z-index: 500
    &.open
      background: #224

    .nav-burger
      padding: 1.5rem
      border-radius: 50%
      background: #33a
      svg
        width: 2rem
        height: 2rem
        color: #fff
    .top-link
      margin: 0 auto

@keyframes expand
  0%
    width: 0
  100%
    width: 100%
</style>

import { defineStore } from 'pinia';
import type { IServer, IToast, IUserData } from '~/types/types';

interface IIndexStore {
  servers: IServer[];
  loadingUserData: boolean;
  user: IUserData | null;
  toast: IToast[];
  screenWidth: number;
}

export const useIndexStore = defineStore('indexStore', {
  state: (): IIndexStore => ({
    servers: [],
    loadingUserData: false,
    user: null,
    toast: [],
    screenWidth: 1,
  }),
  getters: {

  },
  actions: {
  },
});

export default defineNuxtRouteMiddleware(to => {
  const { $store } = useNuxtApp();

  if (process.client) {
    if (!$store.index.loadingUserData && to.path.startsWith('/user') && !$store.index.user) {
      return navigateTo('/');
    }

    if (to.path.startsWith('/payment') && !('success' in to.query || 'cancelled' in to.query)) {
      return navigateTo('/');
    }
  }
});

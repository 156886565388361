<template>
  <div class="toast--wrapper">
    <div
      v-for="(toast, idx) of $store.index.toast"
      :key="`toast-${idx}`"
      class="toast"
      :class="toast.type"
    >
      <Icon v-if="toast.type === 'info'" name="ph:info-bold" />
      <Icon v-if="toast.type === 'error'" name="ph:warning-circle-bold" />

      <div class="flex-col gap-1">
        <span class="toast-title">{{ toast.title }}</span>
        <span
          v-if="toast.errorId"
          class="toast-error-id"
          @click="onToastClick(toast)"
        >
          Error ID: {{ toast.errorId }}
          <Icon name="lucide:copy" class="copy" />
        </span>
        <span class="toast-message">{{ toast.message }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IToast } from '~/types/types';

function onToastClick(toast: IToast) {
  navigator.clipboard.writeText(JSON.stringify(toast));
}
</script>

<style lang="sass" scoped>
.toast
  display: flex
  gap: 2rem
  padding: 1rem 1.5rem
  border-radius: 1rem
  background: #227
  z-index: 105
  animation: .5s linear 0s appear
  align-items: center
  width: 30rem
  max-width: 30rem
  &-title
    font-size: 1.6rem
    font-weight: 600
    color: #fff
  &-message
    font-size: 1.4rem
    color: #fff
  &-error-id
    font-size: 1.2rem
    color: #f66
    cursor: pointer
    &:hover
      text-decoration: underline
  &.info
    .toast-title
      color: #59f
    svg
      color: #59f
  &.error
    .toast-title
      color: #f66
    svg
      color: #f66
  svg
    height: 2.6rem
    width: 2.6rem
    color: #fff
  .copy
    height: 2rem
    width: 2rem
    margin-left: 1rem

  &--wrapper
    display: flex
    flex-direction: column-reverse
    gap: 2rem
    position: fixed
    z-index: 100
    bottom: 2rem
    right: 2rem

@keyframes appear
  0%
    opacity: 0
  100%
    opacity: 1
</style>

export default defineNuxtPlugin(() => {
  const { $store } = useNuxtApp();

  const toast = {
    error(title: string, err: Error) {
      $store.index.toast.push({
        type: 'error',
        title,
        message: err.message,
      });
      setTimeout(() => {
        $store.index.toast.shift();
      }, 10000);
    },
    info(title: string, message?: string) {
      $store.index.toast.push({
        type: 'info',
        title,
        message,
      });
      setTimeout(() => {
        $store.index.toast.shift();
      }, 10000);
    },
  };

  return {
    provide: {
      toast,
    },
  };
});

import type { IFetchResError, IToast } from '~/types/types';

interface IAxisData {
  min: number;
  max: number;
}

export default {
  price: {
    formatted(cost: number) {
      const userLocale = navigator?.languages && navigator?.languages.length ? navigator?.languages[0] : navigator?.language || 'en-US';
      const formatter = new Intl.NumberFormat([ userLocale, 'en-US' ], {
        style: 'currency',
        currency: 'EUR',
      });
      return formatter.format(cost);
    },
  },
  time: {
    formatYMD(date: Date | number | string) {
      if (typeof date === 'number' || typeof date === 'string') {
        date = new Date(date);
      }
      return date.toISOString().split('T')[0];
    },
    suspensionDuration(date1: Date | number | string, date2: Date | number | string) {
      if (typeof date1 === 'number' || typeof date1 === 'string') {
        date1 = new Date(date1);
      }
      if (typeof date2 === 'number' || typeof date2 === 'string') {
        date2 = new Date(date2);
      }
      const diffInMins = Math.ceil(Math.abs(+date1 - +date2) / (1000 * 60));
      if (diffInMins < 60) {
        return `${diffInMins} minutes`;
      }
      if (diffInMins < (60 * 24)) {
        return `${Math.ceil(diffInMins / 60)} hours`;
      }
      return `${Math.ceil(diffInMins / 60 / 24)} days`;
    },
    formatPlaytime(time: number) {
      if (time < 60) {
        return `${time} sec`;
      }
      const days = Math.floor(time / 86400);
      const hours = Math.floor((time % 86400) / 3600);
      const minutes = Math.floor((time % 3600) / 60);

      let timeStr = '';
      if (days) {
        timeStr += `${days} d, `;
      }
      timeStr += `${hours} val, ${minutes} min`;
      return timeStr;
    },
  },
  chart: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    areaChart(title: string, yaxis?: IAxisData, xaxis?: IAxisData) {
      return {
        chart: {
          id: `area-${title.toLowerCase().replaceAll(' ', '-')}`,
          type: 'area',
          zoom: {
            enabled: false,
            autoScaleYaxis: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [ 0, 90, 100 ],
          },
        },
        yaxis: {
          min: yaxis?.min || 0,
          max: yaxis?.max || 1,
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: true,
          x: {
            format: 'dd MMM HH:mm',
          },
        },
      };
    },
  },
  error: {
    handleFetchError(data: IFetchResError) {
      const { $store } = useNuxtApp();
      const errorData: IToast = {
        type: 'error',
        title: 'Error',
      };

      if (data?.operationId) {
        errorData.errorId = data.operationId;
      }
      if (data?.message) {
        errorData.message = data.message;
      }
      $store.index.toast.push(errorData);
      setTimeout(() => {
        $store.index.toast.shift();
      }, 10000);
    },
  },
};
